<template>
   <div class="score-login-war">
    <div class="login-box">
      <div class="head">
        <img :src="require('@/assets/logo.png')" height="20px" class="head-img" alt="" />艺术考级成绩查询
      </div>
      <van-form @submit="submit">
        <van-field
          v-model="idCard"
          :border="false"
          required
          name="idCard"
          label="身份证号"
          placeholder="请输入考生身份证号"
          :rules="[{ required: true, message: '请输入考生身份证号' }]"
        />
        <div style="margin: 26px 16px 16px;">
          <van-button round block type="info" :loading="saveLoading" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
    <img :src="require('@/assets/footer.png')" width="100%" class="footer-img" alt=""> 
  </div>
</template>

<script>
import { Form, Field, Button, Toast  } from 'vant';
import { queryScore } from '@/api'
export default {
    components: {
        [Form.name]: Form,
        [Field.name]: Field,
        [Button.name]: Button,
        [Toast.name]: Toast
    },
    data() {
        return {
          idCard: '',
          saveLoading: false
        }
    },
    methods: {
      submit() {
        const params = {
          idCard: this.idCard
        }
        this.saveLoading = true
        queryScore(params).then(() => {
          this.saveLoading = false
          this.$router.push({path: '/scoreCheck', query: {idCard: this.idCard}})
        }).catch(() => {
          this.saveLoading = false
        })
      }
    }
}
</script>
<style lang="less">
.score-login-war {
  position: relative;
  .van-field__label {
    width: auto !important;
  }
}
</style>
<style lang="less" scoped>
.score-login-war {
  position: relative;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../assets/bg.png');
  padding-bottom: 105px;
  .footer-img {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 20px 80px;
    background: #fff;
  }
  .head {
    font-size: 20px;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
  }
  .login-box {
    width: 90%;
    // border: 1px solid #ccc;
    border-radius: 7px;
    background: #fff;
    padding: 40px 0 25px 0;
  }
}
::v-deep .van-field__control {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 5px;
}
::v-deep .van-cell__title {
    padding: 5px 0;
}
.head-img {
  vertical-align: middle;
  margin-right: 15px;
}
</style>